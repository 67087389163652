define("discourse/plugins/discourse-data-explorer/discourse/components/param-input/category-id-input", ["exports", "@glimmer/component", "select-kit/components/category-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _categoryChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryIdInput extends _component.default {
    // CategoryChooser will try to modify the value of value,
    // triggering a setting-on-hash error. So we have to do the dirty work.
    get data() {
      return {
        value: this.args.field.value
      };
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@field.Custom id={{@field.id}}>
          <CategoryChooser
            @value={{this.data.value}}
            @onChange={{@field.set}}
            name={{@info.identifier}}
          />
        </@field.Custom>
      
    */
    {
      "id": "IE3zo+SS",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"Custom\"]],[[16,1,[30,1,[\"id\"]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[32,0],[[16,3,[30,2,[\"identifier\"]]]],[[\"@value\",\"@onChange\"],[[30,0,[\"data\",\"value\"]],[30,1,[\"set\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@field\",\"@info\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input/category-id-input.js",
      "scope": () => [_categoryChooser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryIdInput;
});