define("discourse/plugins/discourse-data-explorer/discourse/components/param-input-form", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/string", "@ember/utils", "discourse/components/conditional-loading-spinner", "discourse/components/form", "discourse/models/category", "I18n", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/boolean-three", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/category-id-input", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/group-input", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/user-id-input", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/user-list-input", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _object, _service, _string, _utils, _conditionalLoadingSpinner, _form, _category, _I18n, _booleanThree, _categoryIdInput, _groupInput, _userIdInput, _userListInput, _component2, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ParamValidationError = _exports.ERRORS = void 0;
  class ParamValidationError extends Error {}
  _exports.ParamValidationError = ParamValidationError;
  const layoutMap = {
    int: "int",
    bigint: "string",
    boolean: "boolean",
    string: "string",
    time: "time",
    date: "date",
    datetime: "datetime",
    double: "string",
    user_id: "user_id",
    post_id: "string",
    topic_id: "generic",
    category_id: "category_id",
    group_id: "group_list",
    badge_id: "generic",
    int_list: "generic",
    string_list: "generic",
    user_list: "user_list",
    group_list: "group_list"
  };
  const ERRORS = _exports.ERRORS = {
    REQUIRED: _I18n.default.t("form_kit.errors.required"),
    NOT_AN_INTEGER: _I18n.default.t("form_kit.errors.not_an_integer"),
    NOT_A_NUMBER: _I18n.default.t("form_kit.errors.not_a_number"),
    OVERFLOW_HIGH: _I18n.default.t("form_kit.errors.too_high", {
      count: 2147484647
    }),
    OVERFLOW_LOW: _I18n.default.t("form_kit.errors.too_low", {
      count: -2147484648
    }),
    INVALID: _I18n.default.t("explorer.form.errors.invalid"),
    NO_SUCH_CATEGORY: _I18n.default.t("explorer.form.errors.no_such_category"),
    NO_SUCH_GROUP: _I18n.default.t("explorer.form.errors.no_such_group"),
    INVALID_DATE: date => _I18n.default.t("explorer.form.errors.invalid_date", {
      date
    }),
    INVALID_TIME: time => _I18n.default.t("explorer.form.errors.invalid_time", {
      time
    })
  };
  function digitalizeCategoryId(value) {
    value = String(value || "");
    const isPositiveInt = /^\d+$/.test(value);
    if (!isPositiveInt && value.trim()) {
      return _category.default.asyncFindBySlugPath((0, _string.dasherize)(value)).then(res => res.id).catch(err => {
        if (err.jqXHR?.status === 404) {
          throw new ParamValidationError(`${ERRORS.NO_SUCH_CATEGORY}: ${value}`);
        } else {
          throw new Error(err.errorThrow || err.message);
        }
      });
    }
    return value;
  }
  function serializeValue(type, value) {
    switch (type) {
      case "string":
      case "int":
        return value != null ? String(value) : "";
      case "boolean":
        return String(value);
      case "group_list":
      case "user_list":
        return value?.join(",");
      case "group_id":
        return value[0];
      case "datetime":
        return value?.replaceAll("T", " ");
      default:
        return value?.toString();
    }
  }
  function validationOf(info) {
    switch (layoutMap[info.type]) {
      case "boolean":
        return info.nullable ? "required" : "";
      case "string":
      case "string_list":
      case "generic":
        return info.nullable ? "" : "required:trim";
      default:
        return info.nullable ? "" : "required";
    }
  }
  const components = {
    int: (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@field.Input @type="number" name={{@info.identifier}} />
      
    */
    {
      "id": "CcMlXOSE",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],[[\"@type\"],[\"number\"]],null],[1,\"\\n  \"]],[\"@field\",\"@info\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)),
    boolean: (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      <@field.Checkbox name={{@info.identifier}} />
    */
    {
      "id": "Bpy3wqc+",
      "block": "[[[8,[30,1,[\"Checkbox\"]],[[16,3,[30,2,[\"identifier\"]]]],null,null]],[\"@field\",\"@info\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)),
    boolean_three: _booleanThree.default,
    category_id: _categoryIdInput.default,
    user_id: _userIdInput.default,
    user_list: _userListInput.default,
    group_list: _groupInput.default,
    date: (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@field.Input @type="date" name={{@info.identifier}} />
      
    */
    {
      "id": "pjIO2l9A",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],[[\"@type\"],[\"date\"]],null],[1,\"\\n  \"]],[\"@field\",\"@info\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)),
    time: (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@field.Input @type="time" name={{@info.identifier}} />
      
    */
    {
      "id": "ZlffrLV1",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],[[\"@type\"],[\"time\"]],null],[1,\"\\n  \"]],[\"@field\",\"@info\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)),
    datetime: (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@field.Input @type="datetime-local" name={{@info.identifier}} />
      
    */
    {
      "id": "/7gkELiB",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],[[\"@type\"],[\"datetime-local\"]],null],[1,\"\\n  \"]],[\"@field\",\"@info\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)),
    default: (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      <@field.Input name={{@info.identifier}} />
    */
    {
      "id": "2qVZ/n8H",
      "block": "[[[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],null,null]],[\"@field\",\"@info\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined))
  };
  function componentOf(info) {
    let type = layoutMap[info.type] || "generic";
    if (info.nullable && type === "boolean") {
      type = "boolean_three";
    }
    return components[type] || components.default;
  }
  class ParamInputForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    data = {};
    paramInfo = [];
    infoOf = {};
    form = null;
    promiseNormalizations = [];
    formLoaded = (() => new Promise(res => {
      this.__form_load_callback = res;
    }))();
    constructor() {
      super(...arguments);
      this.initializeParams();
      this.args.onRegisterApi?.({
        submit: this.submit,
        allNormalized: Promise.allSettled(this.promiseNormalizations)
      });
    }
    initializeParams() {
      this.args.paramInfo.forEach(info => {
        const identifier = info.identifier;
        const pinfo = this.createParamInfo(info);
        this.paramInfo.push(pinfo);
        this.infoOf[identifier] = info;
        const normalized = this.getNormalizedValue(info);
        if (normalized instanceof Promise) {
          this.handlePromiseNormalization(normalized, pinfo);
        } else {
          this.data[identifier] = normalized;
        }
      });
    }
    createParamInfo(info) {
      return _object.default.create({
        ...info,
        validation: validationOf(info),
        validate: this.validatorOf(info),
        component: componentOf(info)
      });
    }
    async addError(identifier, message) {
      await this.formLoaded;
      this.form.addError(identifier, {
        title: identifier,
        message
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "addError", [_object.action]))();
    normalizeValue(info, value) {
      switch (info.type) {
        case "category_id":
          return digitalizeCategoryId(value);
        case "boolean":
          if (value == null) {
            return info.nullable ? "#null" : false;
          }
          return value;
        case "group_id":
        case "group_list":
          const normalized = this.normalizeGroups(value);
          if (normalized.errorMsg) {
            this.addError(info.identifier, normalized.errorMsg);
          }
          return info.type === "group_id" ? normalized.value.slice(0, 1) : normalized.value;
        case "user_list":
          if (Array.isArray(value)) {
            return value || null;
          }
          return value?.split(",") || null;
        case "user_id":
          if (Array.isArray(value)) {
            return value[0];
          }
          return value;
        case "date":
          try {
            if (!value) {
              return null;
            }
            return moment(new Date(value).toISOString()).format("YYYY-MM-DD");
          } catch {
            this.addError(info.identifier, ERRORS.INVALID_DATE(String(value)));
            return null;
          }
        case "time":
          try {
            if (!value) {
              return null;
            }
            return moment(new Date(`1970/01/01 ${value}`).toISOString()).format("HH:mm");
          } catch {
            this.addError(info.identifier, ERRORS.INVALID_TIME(String(value)));
            return null;
          }
        case "datetime":
          try {
            if (!value) {
              return null;
            }
            return moment(new Date(value).toISOString()).format("YYYY-MM-DD HH:mm");
          } catch {
            this.addError(info.identifier, ERRORS.INVALID_TIME(String(value)));
            return null;
          }
        default:
          return value;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "normalizeValue", [_object.action]))();
    getNormalizedValue(info) {
      const initialValues = this.args.initialValues;
      const identifier = info.identifier;
      return this.normalizeValue(info, initialValues && identifier in initialValues ? initialValues[identifier] : info.default);
    }
    handlePromiseNormalization(promise, pinfo) {
      this.promiseNormalizations.push(promise);
      pinfo.set("loading", true);
      this.data[pinfo.identifier] = null;
      promise.then(res => this.form.set(pinfo.identifier, res)).catch(err => this.addError(pinfo.identifier, err.message)).finally(() => pinfo.set("loading", false));
    }
    normalizeGroups(values) {
      values ||= [];
      if (typeof values === "string") {
        values = values.split(",");
      }
      const GroupNames = new Set(this.site.get("groups").map(g => g.name));
      const GroupNameOf = Object.fromEntries(this.site.get("groups").map(g => [g.id, g.name]));
      const valid_groups = [];
      const invalid_groups = [];
      for (const val of values) {
        if (GroupNames.has(val)) {
          valid_groups.push(val);
        } else if (GroupNameOf[Number(val)]) {
          valid_groups.push(GroupNameOf[Number(val)]);
        } else {
          invalid_groups.push(String(val));
        }
      }
      return {
        value: valid_groups,
        errorMsg: invalid_groups.length !== 0 ? `${ERRORS.NO_SUCH_GROUP}: ${invalid_groups.join(", ")}` : null
      };
    }
    static #_4 = (() => dt7948.n(this.prototype, "normalizeGroups", [_object.action]))();
    getErrorFn(info) {
      const isPositiveInt = value => /^\d+$/.test(value);
      const VALIDATORS = {
        int: value => {
          if (value >= 2147483648) {
            return ERRORS.OVERFLOW_HIGH;
          }
          if (value <= -2147483649) {
            return ERRORS.OVERFLOW_LOW;
          }
          return null;
        },
        bigint: value => {
          if (isNaN(parseInt(value, 10))) {
            return ERRORS.NOT_A_NUMBER;
          }
          return /^-?\d+$/.test(value) ? null : ERRORS.NOT_AN_INTEGER;
        },
        boolean: value => {
          return /^Y|N|#null|true|false/.test(String(value)) ? null : ERRORS.INVALID;
        },
        double: value => {
          if (isNaN(parseFloat(value))) {
            if (/^(-?)Inf(inity)?$/i.test(value) || /^(-?)NaN$/i.test(value)) {
              return null;
            }
            return ERRORS.NOT_A_NUMBER;
          }
          return null;
        },
        int_list: value => {
          return value.split(",").every(i => /^(-?\d+|null)$/.test(i.trim())) ? null : ERRORS.INVALID;
        },
        post_id: value => {
          return isPositiveInt(value) || /\d+\/\d+(\?u=.*)?$/.test(value) || /\/t\/[^/]+\/(\d+)(\?u=.*)?/.test(value) ? null : ERRORS.INVALID;
        },
        topic_id: value => {
          return isPositiveInt(value) || /\/t\/[^/]+\/(\d+)/.test(value) ? null : ERRORS.INVALID;
        },
        category_id: value => {
          return this.site.categoriesById.get(Number(value)) ? null : ERRORS.NO_SUCH_CATEGORY;
        },
        group_list: value => {
          return this.normalizeGroups(value).errorMsg;
        },
        group_id: value => {
          return this.normalizeGroups(value).errorMsg;
        }
      };
      return VALIDATORS[info.type] ?? (() => null);
    }
    validatorOf(info) {
      const getError = this.getErrorFn(info);
      return (name, value, _ref) => {
        let {
          addError
        } = _ref;
        // skip require validation for we have used them in @validation
        if ((0, _utils.isEmpty)(value) || value == null) {
          return;
        }
        const message = getError(value);
        if (message != null) {
          addError(name, {
            title: info.identifier,
            message
          });
        }
      };
    }
    async submit() {
      if (this.form == null) {
        throw "No form";
      }
      this.serializedData = null;
      await this.form.submit();
      if (this.serializedData == null) {
        throw new ParamValidationError("validation_failed");
      } else {
        return this.serializedData;
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
    onRegisterApi(form) {
      this.__form_load_callback();
      this.form = form;
    }
    static #_6 = (() => dt7948.n(this.prototype, "onRegisterApi", [_object.action]))();
    onSubmit(data) {
      const serializedData = {};
      for (const [id, val] of Object.entries(data)) {
        serializedData[id] = serializeValue(this.infoOf[id].type, val) ?? undefined;
      }
      this.serializedData = serializedData;
    }
    static #_7 = (() => dt7948.n(this.prototype, "onSubmit", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="query-params">
          <Form
            @data={{this.data}}
            @onRegisterApi={{this.onRegisterApi}}
            @onSubmit={{this.onSubmit}}
            class="params-form"
            as |form|
          >
            {{#each this.paramInfo as |info|}}
              <div class="param">
                <form.Field
                  @name={{info.identifier}}
                  @title={{info.identifier}}
                  @validation={{info.validation}}
                  @validate={{info.validate}}
                  as |field|
                >
                  <info.component @field={{field}} @info={{info}} />
                  <ConditionalLoadingSpinner
                    @condition={{info.loading}}
                    @size="small"
                  />
                </form.Field>
              </div>
            {{/each}}
          </Form>
        </div>
      
    */
    {
      "id": "SITNBy0I",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"query-params\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"params-form\"]],[[\"@data\",\"@onRegisterApi\",\"@onSubmit\"],[[30,0,[\"data\"]],[30,0,[\"onRegisterApi\"]],[30,0,[\"onSubmit\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"paramInfo\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"param\"],[12],[1,\"\\n            \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\",\"@validation\",\"@validate\"],[[30,2,[\"identifier\"]],[30,2,[\"identifier\"]],[30,2,[\"validation\"]],[30,2,[\"validate\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[8,[30,2,[\"component\"]],null,[[\"@field\",\"@info\"],[[30,3],[30,2]]],null],[1,\"\\n              \"],[8,[32,1],null,[[\"@condition\",\"@size\"],[[30,2,[\"loading\"]],\"small\"]],null],[1,\"\\n            \"]],[3]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"]],[1]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"form\",\"info\",\"field\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
      "scope": () => [_form.default, _conditionalLoadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ParamInputForm;
});